'use client'

import { CacheProvider } from '@chakra-ui/next-js'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
// import dayjs from 'dayjs';
// import 'dayjs/locale/zh-tw'
import { initFirebase } from 'src/firebase/firebase';
import GlobalProvider from 'src/context/globalContext';
import UserProvider from 'src/context/userContext'

// 因 globally 改變 locale 會造成 react-tailwindcss-datepicker 顯示異常，所以必須在用到的地方 locally 設定；若此問題修正再回來開啟此處
// dayjs.locale('zh-tw'); 

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: [
          '"Noto Sans TC"',
          'Roboto',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Microsoft JhengHei"',
          '"Segoe UI"',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      }
    }
  },
  colors: {
    primary: {
      50: "#f3f6fc",
      100: "#e5ecf9",
      200: "#c6d8f1",
      300: "#93b8e6",
      400: "#5993d7",
      500: "#3476c3",
      600: "#2660ad",
      700: "#1e4986",
      800: "#1d3f6f",
      900: "#1d365d",
    },
    secondary: {
      50: "#fdf4f3",
      100: "#fbe7e5",
      200: "#f9d4cf",
      300: "#f4b5ad",
      400: "#eb8a7e",
      500: "#db5443",
      600: "#ca4838",
      700: "#aa392b",
      800: "#8d3227",
      900: "#753027",
    }
  },
  // Follow BP of tailwind
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  }
})

initFirebase();

export function Providers({ children }) {
  return (
    <CacheProvider>
      <ChakraProvider theme={theme}>
        <GlobalProvider>
          <UserProvider>
            {children}
          </UserProvider>
        </GlobalProvider>
      </ChakraProvider>
    </CacheProvider>
  )
}