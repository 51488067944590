import(/* webpackMode: "eager" */ "/app/app/providers.jsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-roboto\"}],\"variableName\":\"roboto\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Noto_Sans_TC\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--font-noto-sans\"}],\"variableName\":\"notoSansTc\"}");
import(/* webpackMode: "eager" */ "/app/app/globals.css");
import(/* webpackMode: "eager" */ "/app/src/components/common/Footer.jsx");
import(/* webpackMode: "eager" */ "/app/src/components/common/GoTopButton.jsx");
import(/* webpackMode: "eager" */ "/app/src/components/common/Header.jsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/AchievementsPage/AchievementsPageLayout.jsx")